<template>
  <div class="main">
    <div class="nav_box">
      <div
        @click="changeNav(1)"
        :class="nav === 1 ? 'current' : 'nav'"
      >
        web端
      </div>
      <div
        @click="changeNav(2)"
        :class="nav === 2 ? 'current' : 'nav'"
      >
        移动端
      </div>
    </div>
    <!-- 添加 修改用户弹窗 -->
    <keep-alive>
      <Component :is="getComponent" />
    </keep-alive>
  </div>
</template>

<script>
import TabOne from './tab_one.vue'
import TabTwo from './tab_two.vue'

export default {
  name: 'user',
  components: { TabOne, TabTwo },
  data() {
    return {
      // 1web端 2PC端
      nav: 1
    }
  },
  methods: {
    // 切换nav
    changeNav(nav) {
      if (this.nav === nav) return
      this.nav = nav
    }
  },
  computed: {
    getComponent() {
      if (this.nav === 1) {
        return TabOne
      } else {
        return TabTwo
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.nav_box {
  height: 48px;
  border-bottom: 2px solid #eff2f7;
  display: flex;
  background-color: #fff;

  .nav {
    height: 100%;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-left: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .current {
    @extend .nav;
    color: #2a47b0;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 65px;
      height: 4px;
      background: #2a47b0;
    }
  }
}</style>
