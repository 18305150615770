<template>
  <div class="mobile_account">
    <div class="select_box">
      <!-- 运营商 -->
      <el-select
        class="ipt_box mr12 mt12"
        v-supAdmin
        v-model="id_operator"
        placeholder="请选择运营商"
      >
        <el-option
          v-for="item in operatorList"
          :key="item.id_operator"
          :label="item.name_operator"
          :value="item.id_operator"
        ></el-option>
      </el-select>

      <!-- 用户名 -->
      <el-input
        class="ipt_box mr12 mt12"
        v-model="name_user"
        placeholder="请输入用户名"
      ></el-input>

      <!-- 姓名 -->
      <el-input
        class="ipt_box mr12 mt12"
        v-model="real_name"
        placeholder="请输入姓名"
      ></el-input>

      <!-- 手机号 -->
      <el-input
        class="ipt_box mr12 mt12"
        v-model="tel"
        placeholder="请输入手机号"
      ></el-input>

      <!-- 添加日期 -->
      <el-date-picker
        class="time_box mr12 mt12"
        v-model="date"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>

      <div class="mr12 mt12">
        <el-button
          type="primary"
          size="small"
          @click="search"
        >查询</el-button>
        <el-button
          size="small"
          @click="reset"
        >重置</el-button>
      </div>
    </div>

    <!-- 表格 -->
    <div class="table_box">
      <div class="add_user">
        <i
          class="el-icon-plus"
          color="#2a47b0"
          @click="addUser"
        ></i>
        <span @click="addUser">添加用户</span>
      </div>

      <el-table
        :data="userList"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#fafafb',
          height: '40px'
        }"
        :stripe="true"
        size="small"
      >
        <el-table-column
          prop="name_user"
          label="用户名"
        ></el-table-column>
        <el-table-column
          prop="real_name"
          label="姓名"
        ></el-table-column>
        <el-table-column
          prop="name_operator"
          label="运营商"
          v-if="role === 100"
        ></el-table-column>
        <el-table-column
          prop="tel"
          label="手机号"
        ></el-table-column>
        <el-table-column
          prop="time_create"
          label="添加日期"
        ></el-table-column>
        <el-table-column label="最后登录日期">
          <template v-slot="scope">
            <span>{{ scope.row.time_login || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <span
              class="edit"
              @click="editUser(scope.row)"
            >
              <i class="el-icon-edit"></i>
              <span>修改</span>
            </span>
            <span
              class="del"
              @click="delUser(scope.row)"
            >
              <i class="el-icon-delete"></i>
              <span>删除</span>
            </span>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <div class="pagination_box">
        <el-pagination
          @size-change="sizeChange"
          @current-change="CurrentChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加 修改用户 -->
    <ChangeUser
      ref="changeUserRef"
      @refresh="getAccountList"
    />
  </div>
</template>

<script>
import { GetAppAccount, UpdateAppUser, GetOperatorList } from '@/api'
import ChangeUser from './tab_two/change_user.vue'

export default {
  components: { ChangeUser },
  data() {
    return {
      // 用户权限
      role: '',
      // 运营商列表
      operatorList: [],
      // 选中的运营商
      id_operator: '',
      // 用户名
      name_user: '',
      // 姓名
      real_name: '',
      // 联系方式
      tel: '',
      // 日期
      date: ['', ''],
      // 用户列表
      userList: [],
      page_id: 0,
      page_num: 20,
      total: 0
    }
  },
  methods: {
    // 页面显示多少条数据
    sizeChange(s) {
      this.page_num = s
      this.page_id = 0
      this.getAccountList()
    },

    // 页码变化
    CurrentChange(p) {
      this.page_id = p - 1
      this.getAccountList()
    },

    // 点击查询
    search() {
      this.getAccountList()
    },

    // 点击重置
    reset() {
      this.name_user = this.real_name = this.tel = ''
      this.id_operator = ''
      this.date = ['', '']
      this.page_id = 0
      this.getAccountList()
    },

    // 获取参数列表
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }

      if (this.id_operator) {
        params.id_operator = this.id_operator
      }

      if (this.name_user.trim()) {
        params.name_user = this.name_user.trim()
      }

      if (this.real_name.trim()) {
        params.real_name = this.real_name.trim()
      }

      if (this.tel.trim()) {
        params.tel = this.tel.trim()
      }

      return params
    },

    // 获取移动端账号列表
    async getAccountList() {
      const params = this.getParams()
      const { ret, data, msg } = await GetAppAccount(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.total = data.data_cnt
      this.userList = data.data_list
    },

    // 添加用户
    addUser() {
      this.$refs.changeUserRef.addUser()
    },

    // 编辑用户
    editUser(row) {
      this.$refs.changeUserRef.editUser(row)
    },

    // 删除用户
    async delUser(row) {
      const result = await this.$confirm('您确定要删除该用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => 'cancel')
      if (result === 'cancel') return
      const params = {
        id: row.id,
        state: 0
      }
      const { ret, data, msg } = await UpdateAppUser(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('删除成功')
      this.getAccountList()
    },

    // 获取运营商列表
    async getOperatorList() {
      const params = {}
      const { ret, data, msg } = await GetOperatorList(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.operatorList = data.data
    }
  },

  created() {
    this.getAccountList()
    this.role = this.$store.getters.role
    if (this.role === 100) {
      this.getOperatorList()
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile_account {
  width: 100%;
  min-height: calc(100% - 40px);
  background-color: #fff;
  border-radius: $radius;
  padding: $padding;
}

.table_box {
  padding: 0 12px 12px;

  .add_user {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $theme;
    cursor: pointer;

    i {
      font-size: 14px;
    }

    span {
      margin-left: 4px;
      margin-right: 4px;
      font-size: 14px;
    }
  }

  .edit {
    cursor: pointer;
    margin: 0 5px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2a47b0;

    i {
      color: #2a47b0;
      margin-right: 3px;
    }
  }

  .del {
    cursor: pointer;
    margin: 0 5px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #d94f4f;

    i {
      color: #d94f4f;
      margin-right: 3px;
    }
  }
}
</style>
