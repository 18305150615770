<template>
  <div class="user">
    <div class="select_box">
      <!-- 用户ID -->
      <el-input
        v-model="id"
        class="ipt_box mr12 mt12"
        placeholder="请输入用户ID"
      ></el-input>

      <!-- 用户名 -->
      <el-input
        class="ipt_box mr12 mt12"
        v-model="name_user"
        placeholder="请输入用户名"
      ></el-input>

      <!-- 姓名 -->
      <el-input
        class="mr12 mt12 ipt_box"
        v-model="nick"
        placeholder="请输入姓名"
      ></el-input>

      <!-- 运营商 -->
      <el-select
        class="ipt_box mr12 mt12"
        v-supAdmin
        v-model="id_operator"
        placeholder="请选择运营商"
        @change="() => (role = '')"
      >
        <el-option
          v-for="item in operatorList"
          :key="item.id_operator"
          :label="item.name_operator"
          :value="item.id_operator"
        ></el-option>
      </el-select>

      <!-- 权限类型 -->
      <el-select
        class="ipt_box mr12 mt12"
        v-model="role"
        placeholder="请选择权限类型"
      >
        <el-option
          v-for="item in computedRoleList"
          :key="item.role"
          :label="item.role_name"
          :value="item.role"
        ></el-option>
      </el-select>

      <!-- 添加日期 -->
      <el-date-picker
        class="time_box mr12 mt12"
        v-model="date"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>

      <div class="mr12 mt12">
        <el-button
          type="primary"
          size="small"
          @click="search"
        >查询</el-button>
        <el-button
          type="primary"
          size="small"
          plain
          @click="reset"
        >重置</el-button>
      </div>
    </div>

    <div class="table_box">
      <div class="add_user">
        <i
          class="el-icon-plus"
          color="#2a47b0"
          @click="addUser"
        ></i>
        <span @click="addUser">添加用户</span>
      </div>

      <el-table
        :data="userList"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#fafafb',
          height: '40px'
        }"
        :stripe="true"
        size="small"
      >
        <el-table-column
          prop="id"
          label="用户ID"
        ></el-table-column>
        <el-table-column
          prop="name_user"
          label="用户名"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="姓名"
        ></el-table-column>
        <el-table-column
          prop="name_operator"
          label="运营商"
          v-if="user_role === 100"
        ></el-table-column>
        <el-table-column
          prop="name_user"
          label="权限类型"
        >
          <template v-slot="scope">
            {{ scope.row.role_name }}
          </template>
        </el-table-column>
        <el-table-column
          prop="tel"
          label="手机号"
        ></el-table-column>
        <el-table-column
          label="添加日期"
          prop="time_create"
        ></el-table-column>
        <el-table-column
          label="最后登录日期"
          prop="time_update"
        ></el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <span
              class="edit_table_box cursor"
              @click="editUser(scope.row)"
            >
              <i
                class="el-icon-edit-outline"
                style="color: #2a47b0"
              ></i>
              <span class="edit_table">编辑</span>
            </span>

            <span
              class="del_table_box cursor"
              @click="delUser(scope.row)"
            >
              <i
                class="el-icon-delete"
                style="color: red"
              ></i>
              <span
                class="edit_table"
                style="color: red"
              >删除</span>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination_box">
      <el-pagination
        @size-change="sizeChange"
        @current-change="CurrentChange"
        :current-page="page_id + 1"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="page_num"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <ChangeUser
      ref="changeUserRef"
      @refresh="getUserList"
    />
  </div>
</template>
<script>
import {
  GetUserList,
  GetRoleList,
  UpdateUserInfo,
  GetOperatorList
} from '@/api'
import ChangeUser from './tab_one/change_user.vue'

export default {
  name: 'user',
  components: { ChangeUser },
  data() {
    return {
      user_role: '',
      // 用户id
      id: '',
      // 用户名
      name_user: '',
      // 姓名
      nick: '',
      // 运营商列表
      operatorList: [],
      // 运营商id
      id_operator: '',
      // 创建时间
      date: ['', ''],
      // 角色类型
      role: '',
      page_id: 0,
      page_num: 20,
      // 总数据条数
      total: 0,
      // 角色列表
      roleList: [],
      // 数据
      userList: []
    }
  },

  methods: {
    // 页面显示多少条数据
    sizeChange(s) {
      this.page_num = s
      this.page_id = 0
      this.getUserList()
    },

    // 页码变化
    CurrentChange(p) {
      this.page_id = p
      this.getUserList()
    },

    // 查询
    search() {
      this.page_id = 0
      this.getUserList()
    },

    // 重置
    reset() {
      this.id = this.name_user = this.nick = this.role = ''
      this.id_operator = ''
      this.date = null
      this.getUserList()
    },

    // 获取参数
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }

      if (this.id_operator) {
        params.id_operator = this.id_operator
      }

      if (this.id.trim()) {
        params.id = this.id.trim()
      }
      if (this.name_user.trim()) {
        params.name_user = this.name_user.trim()
      }
      if (this.nick.trim()) {
        params.nick = this.nick.trim()
      }
      if (this.role) {
        params.role = this.role
      }
      if (this.date && this.date[0] && this.date[1]) {
        params.time_create = this.date[0] + ' 00:00:00'
        params.time_end = this.date[1] + ' 23:59:59'
      }
      return params
    },

    // 获取角色列表
    async getRole() {
      const { ret, data, msg } = await GetRoleList()
      if (ret !== 0) {
        return this.$message.error(msg || '获取角色列表')
      }
      const roles = []
      data.data.map(i => {
        roles.push({
          role_name: i.role_name,
          role: i.id,
          id_operator: i.id_operator
        })
      })
      this.roleList = roles
    },

    // 获取用户列表
    async getUserList() {
      const params = this.getParams()
      const { ret, msg, data } = await GetUserList(params)
      if (ret !== 0) {
        return this.$message.error(msg || '获取用户列表失败!')
      }
      this.total = data.cnt_all
      this.userList = data.data
    },

    // 修改状态
    async delUser(row) {
      const result = await this.$confirm('您确定要删除该用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => 'cancel')
      if (result === 'cancel') return
      const params = {
        id: row.id,
        state: 0
      }
      const { ret, data, msg } = await UpdateUserInfo(params)
      if (ret !== 0) {
        return this.$message.error(msg || '删除用户失败!')
      }
      this.$message.success('删除成功')
      this.getUserList()
    },

    // 添加用户
    addUser() {
      this.$refs.changeUserRef.add(this.roleList)
    },

    // 编辑用户
    editUser(row) {
      this.$refs.changeUserRef.edit(row, this.roleList)
    },

    // 获取运营商列表
    async getOperatorList() {
      const params = {}
      const { ret, data, msg } = await GetOperatorList(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.operatorList = data.data
    }
  },

  created() {
    this.user_role = this.$store.getters.role
    this.getRole()
    this.getUserList()
    if (this.user_role === 100) {
      this.getOperatorList()
    }
  },

  computed: {
    // 动态计算权限列表
    computedRoleList() {
      if (this.user_role === 100) {
        if (this.id_operator) {
          return this.roleList.filter(i => i.id_operator === this.id_operator)
        } else {
          return this.roleList
        }
      } else {
        return this.roleList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
  width: 100%;
  min-height: calc(100% - 40px);
  background: #fff;
  border-radius: $radius;
  padding: $padding;

  .table_box {
    margin-top: 12px;
    padding: 0 12px 12px;

    .add_user {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: $theme;
      cursor: pointer;

      i {
        font-size: 14px;
      }

      span {
        margin-left: 4px;
        font-size: 14px;
      }
    }
  }
}
</style>
