<template>
  <el-dialog
    :title="isEdit ? '修改用户' : '添加用户'"
    :visible.sync="dialogVisible"
    width="678px"
    :close-on-click-modal="false"
    @closed="resetForm"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="用户名：" prop="name_user">
            <el-input
              v-model.trim="ruleForm.name_user"
              maxlength="24"
              :disabled="isEdit"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="姓名：" prop="real_name">
            <el-input v-model="ruleForm.real_name" maxlength="4"></el-input>
          </el-form-item>
        </el-col>
        <template v-if="!isEdit">
          <el-col :span="12">
            <el-form-item label="初始密码：" prop="pwd">
              <el-input
                v-model.trim="ruleForm.pwd"
                type="password"
                maxlength="16"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认密码：" prop="pwd_confirm">
              <el-input
                v-model.trim="ruleForm.pwd_confirm"
                type="password"
                maxlength="16"
              ></el-input>
            </el-form-item>
          </el-col>
        </template>
        <template v-else>
          <el-col :span="12">
            <el-form-item label="重置密码：">
              <el-input
                v-model.trim="ruleForm.pwd"
                type="password"
                maxlength="16"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认密码：">
              <el-input
                v-model.trim="ruleForm.pwd_confirm"
                type="password"
                maxlength="16"
              ></el-input>
            </el-form-item>
          </el-col>
        </template>
        <el-col :span="12">
          <el-form-item label="手机号：" prop="tel">
            <el-input v-model.trim="ruleForm.tel" maxlength="11"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="role === 100 && !isEdit">
          <el-form-item label="运营商：" prop="id_operator">
            <el-select
              v-model="ruleForm.id_operator"
              placeholder="请选择"
              style="width: 100%"
              @change="operatorChange"
            >
              <el-option
                v-for="item in operatorList"
                :key="item.id_operator"
                :label="item.name_operator"
                :value="item.id_operator"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="options.length">
        <el-col :span="24">
          <el-form-item label="操作系统：">
            <el-cascader
              :options="options"
              :props="{ multiple: true }"
              clearable
              v-model="cascaderSelected"
            ></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  GetOperatorData,
  GetProjectList,
  AddAppAccount,
  UpdateAppUser
} from '@/api'
import MD5 from 'md5'

const checkNameUser = (rule, value, cb) => {
  const reg = /^[^\u4E00-\u9FFF]+$/
  if (!reg.test(value)) {
    return cb(new Error('用户名不允许输入中文字符'))
  }
  cb()
}

const checkTel = (rule, value, cb) => {
  const reg = /^1[0-9]{10}$/
  if (!reg.test(value)) {
    return cb(new Error('请检查手机号输入'))
  }
  cb()
}

// 新密码需要验证
const checkPwd = (rule, value, cb) => {
  const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/
  if (value.length < 6 || value.length > 16) {
    return cb(new Error('密码长度错误'))
  }
  if (!reg.test(value)) {
    return cb(new Error('密码需包含‘大小写字母’和‘数字’'))
  }
  cb()
}

export default {
  data() {
    return {
      // 是否为编辑
      isEdit: false,
      // 角色
      role: '',
      // 是否显示对话框
      dialogVisible: false,
      // 表单
      ruleForm: {
        id: '',
        name_user: '',
        real_name: '',
        tel: '',
        pwd: '',
        pwd_confirm: '',
        id_operator: ''
      },
      // 选中的系统
      cascaderSelected: [],
      // 运营商列表
      operatorList: [],
      // 规则
      rules: {
        name_user: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { validator: checkNameUser, trigger: 'blur' }
        ],
        real_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkTel, trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: checkPwd, trigger: 'blur' }
        ],
        pwd_confirm: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: checkPwd, trigger: 'blur' }
        ],

        id_operator: [
          { required: true, message: '请选择运营商', trigger: 'change' }
        ]
      },
      options: []
    }
  },
  methods: {
    // 重置表单
    resetForm() {
      this.$refs.ruleForm.resetFields()
      this.cascaderSelected = []
      this.operatorList = []
      this.options = []
      this.ruleForm.pwd = ''
      this.ruleForm.pwd_confirm = ''
    },

    // 外部调用添加
    addUser() {
      this.isEdit = false
      this.role = this.$store.getters.role
      if (this.role === 100) {
        this.getOperatorList()
      } else {
        this.operatorChange()
      }
      this.dialogVisible = true
    },

    // 外部调用修改
    editUser(info) {
      this.isEdit = true
      this.dialogVisible = true
      this.operatorChange(info.id_operator, true, info.device_array)
      this.$nextTick(() => {
        this.ruleForm.id = info.id
        this.ruleForm.name_user = info.name_user
        this.ruleForm.real_name = info.real_name
        this.ruleForm.tel = info.tel
      })
    },

    // 超管 获取运营商列表
    async getOperatorList() {
      const { ret, data, msg } = await GetOperatorData()
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.operatorList = data.data
    },

    // 选中的运营商发生变化
    // 传递第二个参数必须追加第三个参数
    async operatorChange(id, isEdit = false, deviceStr) {
      const params = {}
      if (id) {
        params.id_operator = id
      }
      const { ret, data, msg } = await GetProjectList(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      const options = []
      data.data.map((i) => {
        const temp = {
          value: i.id,
          label: i.name_project
        }
        const children = []
        i.device_list.map((j) => {
          children.push({
            value: j.id_device,
            label: j.name
          })
        })
        temp.children = children
        options.push(temp)
      })
      this.options = options
      if (isEdit) {
        // 回显项目
        this.reShowSelect(options, deviceStr.split(','))
      }
    },

    // 项目回显
    reShowSelect(options, deviceList) {
      const result = []
      options.map((i) => {
        i.children.map((j) => {
          if (deviceList.includes(j.value)) {
            result.push([i.value, j.value])
          }
        })
      })
      this.cascaderSelected = result
    },

    // 点击确定
    confirm() {
      this.$refs.ruleForm.validate(async (_) => {
        if (!_) return
        // 判断选中的系统个数
        if (!this.cascaderSelected.length) {
          this.$message.warning('请选择管理的系统')
          return
        }

        if (this.isEdit) {
          this.confirmEditUser()
        } else {
          this.confirmAddUser()
        }
      })
    },

    // 确定添加用户
    async confirmAddUser() {
      const params = {
        name_user: this.ruleForm.name_user,
        real_name: this.ruleForm.real_name,
        tel: this.ruleForm.tel,
        pwd: MD5(this.ruleForm.pwd),
        pwd_confirm: MD5(this.ruleForm.pwd_confirm)
      }
      // 动态添加系统
      const systems = []
      this.cascaderSelected.map((i) => {
        systems.push(i[i.length - 1])
      })
      params.device_array = systems
      // 动态添加运营商id
      if (this.role === 100) {
        params.id_operator = this.ruleForm.id_operator
      }
      const { ret, data, msg } = await AddAppAccount(params)
      if (ret === 25) {
        return this.$message.warning('两次输入的密码不一致!')
      }
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('refresh')
    },

    // 确定编辑用户
    async confirmEditUser() {
      // 判断是否输入了密码 只输了密码
      if (this.ruleForm.pwd && !this.ruleForm.pwd_confirm) {
        return this.$message.warning('请输入确认密码')
      }
      // 只输了确认密码
      if (!this.ruleForm.pwd && this.ruleForm.pwd_confirm) {
        return this.$message.warning('请输入密码')
      }
      // 这里只要有一个输入了, 肯定是都输入了
      if (this.ruleForm.pwd || this.ruleForm.pwd_confirm) {
        // 校验长度
        if (this.ruleForm.pwd.length < 6) {
          return this.$message.warning('重置密码长度为 6 - 16 位')
        }
        if (this.ruleForm.pwd_confirm.length < 6) {
          return this.$message.warning('确认密码长度为 6 - 16 位')
        }
        // 校验难度
        const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/
        if (!reg.test(this.ruleForm.pwd)) {
          return this.$message.warning('密码需包含‘大小写字母’和‘数字’')
        }
        if (!reg.test(this.ruleForm.pwd_confirm)) {
          return this.$message.warning('密码需包含‘大小写字母’和‘数字’')
        }
      }
      // 都校验通过了
      const params = {
        id: this.ruleForm.id,
        real_name: this.ruleForm.real_name,
        tel: this.ruleForm.tel
      }
      if (this.ruleForm.pwd) {
        params.pwd = MD5(this.ruleForm.pwd)
        params.pwd_confirm = MD5(this.ruleForm.pwd_confirm)
      }
      const systems = []
      this.cascaderSelected.map((i) => {
        systems.push(i[i.length - 1])
      })
      params.device_array = systems
      const { ret, data, msg } = await UpdateAppUser(params)
      if (ret === 25) {
        return this.$message.warning('两次输入的密码不一致!')
      }
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('更新成功')
      this.dialogVisible = false
      this.$emit('refresh')
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
