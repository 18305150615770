<template>
  <el-dialog
    :title="isEdit ? '编辑用户' : '添加用户'"
    :visible.sync="dialogVisible"
    width="678px"
    @closed="resetForm"
    :close-on-click-modal="false"
  >
    <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">
      <el-row v-if="role === 100">
        <el-col :span="12">
          <el-form-item label="运营商：" prop="id_operator">
            <el-select v-model="form.id_operator" placeholder="请选择">
              <el-option
                v-for="item in operatorList"
                :key="item.id_operator"
                :label="item.name_operator"
                :value="item.id_operator"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="用户名：" prop="name_user">
            <el-input
              v-model="form.name_user"
              maxlength="24"
              :disabled="isEdit"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="姓名：" prop="remark">
            <el-input v-model="form.remark" maxlength="4"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="isEdit ? '重置密码：' : '初始密码：'"
            :prop="isEdit ? '' : 'pwd'"
          >
            <el-input
              v-model="form.pwd"
              type="password"
              maxlength="16"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="确认密码：" :prop="isEdit ? '' : 'pwd'">
            <el-input
              v-model="form.confirm_pwd"
              type="password"
              maxlength="16"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号：" prop="tel">
            <el-input v-model="form.tel" maxlength="11"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="权限类型：" prop="role">
            <el-select
              v-model="form.role"
              placeholder="请选择"
              :disabled="role === 100 && !form.id_operator"
            >
              <el-option
                v-for="item in computedRoleList"
                :key="item.role"
                :label="item.role_name"
                :value="item.role"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import MD5 from 'md5'
import { AddUser, UpdateUserInfo, GetOperatorList } from '@/api'

const checkNameUser = (rule, value, callback) => {
  const reg = /^[(0-9)|(a-z)|(A-Z)]{1,24}$/
  if (!reg.test(value)) {
    callback(new Error('用户名只能输入 0~9 a~z A~Z'))
  }
  callback()
}

const checkPwd = (rule, value, callback) => {
  // 限制 6 - 16位
  if (value.trim().length < 6 || value.trim().length > 16) {
    return callback(new Error('密码长度为 6 ~ 16 个字符'))
  }
  const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/
  if (!reg.test(value.trim())) {
    return callback(new Error("密码必须包含'大小写字母'和'数字'"))
  }
  callback()
}

const checkTel = (rule, value, callback) => {
  const reg = /^1[0-9]{10}$/
  if (!reg.test(value)) {
    callback(new Error('手机号输入错误'))
  }
  callback()
}

export default {
  data() {
    return {
      // 100 超级管理员
      role: '',
      // 运营商列表
      operatorList: [],
      // 父组件带过来的权限列表
      roleList: [],
      // 是否显示对话框4
      dialogVisible: false,
      // 是否为编辑用户
      isEdit: false,
      // 表单
      form: {
        id: '',
        id_operator: '',
        name_user: '',
        remark: '',
        pwd: '',
        confirm_pwd: '',
        tel: '',
        role: '',
        state: 1
      },
      // 校验规则
      rules: {
        id_operator: [
          { required: true, message: '请选择运营商', trigger: 'change' }
        ],
        name_user: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          },
          {
            max: 24,
            validator: checkNameUser,
            trigger: 'blur'
          }
        ],
        remark: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          },
          {
            max: 4,
            message: '长度为 0~4 位'
          }
        ],
        pwd: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            validator: checkPwd,
            trigger: 'blur'
          }
        ],
        tel: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            validator: checkTel,
            trigger: 'blur'
          }
        ],
        role: [
          {
            required: true,
            message: '请选择权限类型',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    // 添加用户
    add(roleList) {
      this.role = this.$store.getters.role
      this.roleList = roleList
      this.isEdit = false
      this.dialogVisible = true
      if (this.role === 100) {
        this.getOperatorList()
      }
    },

    // 获取运营商列表
    async getOperatorList() {
      const { ret, data, msg } = await GetOperatorList()
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.operatorList = data.data
    },

    // 编辑用户
    edit(row, roleList) {
      this.roleList = roleList
      this.isEdit = true
      this.dialogVisible = true
      this.$nextTick(() => {
        this.form.id = row.id
        this.form.name_user = row.name_user
        this.form.remark = row.remark
        this.form.tel = row.tel
        this.form.role = row.role
      })
    },

    // 重置表单
    resetForm() {
      this.$refs.formRef.resetFields()
      this.form.confirm_pwd = ''
    },

    // 点击确定
    confirm() {
      this.$refs.formRef.validate(async _ => {
        if (!_) return
        if (this.isEdit) {
          this.editUser()
        } else {
          this.addUser()
        }
      })
    },

    // 添加用户
    async addUser() {
      const form = this.form
      if (form.pwd !== form.confirm_pwd) {
        this.$message.warning('两次输入的密码不一致')
        return
      }
      const params = {
        name_user: form.name_user,
        remark: form.remark,
        pwd: MD5(form.pwd),
        role: form.role,
        tel: form.tel
      }
      const { ret, data, msg } = await AddUser(params)
      if (ret !== 0) {
        return this.$message.error(msg || '添加用户失败!')
      }
      this.$message.success('添加成功')
      this.$emit('refresh')
      this.dialogVisible = false
    },

    // 编辑用户
    async editUser() {
      const form = this.form
      // 至少输了一个密码
      if (form.pwd !== '' || form.confirm_pwd !== '') {
        if (form.pwd.length < 6 || form.pwd.length > 16) {
          return this.$message.warning('重置密码长度为 6 ~ 16 位')
        }
        if (form.pwd !== form.confirm_pwd) {
          return this.$message.warning('两次输入的密码不一样')
        }
        const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/
        if (!reg.test(form.pwd)) {
          return this.$message.warning("密码必须包含'大小写字母'和'数字'")
        }
      }
      const params = {
        id: form.id,
        remark: form.remark,
        role: form.role,
        tel: form.tel
      }
      if (form.pwd) {
        params.pwd = MD5(form.pwd)
      }
      const { ret, data, msg } = await UpdateUserInfo(params)
      if (ret !== 0) {
        return this.$message.error(msg || '修改用户失败!')
      }
      this.$message.success('修改用户成功')
      this.$emit('refresh')
      this.dialogVisible = false
    }
  },

  computed: {
    computedRoleList() {
      if (this.role === 100) {
        if (this.form.id_operator) {
          return this.roleList.filter(
            i => i.id_operator === this.form.id_operator
          )
        } else {
          return this.roleList
        }
      } else {
        return this.roleList
      }
    }
  }
}
</script>
